.btn-scroll {
	transition: background ($transition-time / 2) ease-out;
	width: 26px;
	height: 26px;
	background: $brand-primary;
	border-radius: 100%;
	position: absolute;
	left: 50%;
	margin-left: -13px;
	box-shadow: none;
	border: 0;
	z-index: 5;
	overflow: hidden;

	&:hover {
		cursor: pointer;
		border-color: $white;
	}

	// before
	&::before {
		content: "";
		width: 8px;
		height: 8px;
		border-radius: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -4px;
		margin-top: -4px;
		border-width: 2px;
		border-style: solid;
	}
}

.btn-scroll--down {
	bottom: 80px;
	box-shadow: inset 0 -10px rgba(0,0,0,0.15);

	@include media-breakpoint-up(md) {
		bottom: 40px;
	}

	&::before {
		border-color: transparent rgba(255,255,255,0.5) rgba(255,255,255,0.5) transparent;
		transform: rotate(45deg);
		animation: down-arrow $transition-time ease-in-out 2s infinite alternate;
		z-index: 2;
	}

	&::after {
		transition: transform $transition-time $transition-bez;
		content: "";
		position: absolute;
		background: rgba(255,255,255,0.5);
		top: 0;
		left: 0;
		border-radius: 100%;
		width: 100%;
		height: 100%;
		transform: scaleX(0);
	  	transform-origin: right;
	  	z-index: 1;
	}

	&:hover::before {
		border-color: transparent $gray-darker $gray-darker transparent;
	}

	&:hover::after {
		transform: scaleX(1);
		transform-origin: left;
		transition-delay: 0s;
	}
}

@keyframes down-arrow {
    from {
      transform: rotate(45deg) translate(1px, 1px);
    }
    to {
      transform: rotate(45deg) translate(-1px, -1px);
    }
}


.btn-scroll--up {
	top: 80px;
	box-shadow: inset 0 10px rgba(0,0,0,0.15);

	@include media-breakpoint-up(md) {
		top: 90px;
	}

	&::before {
		border-color: rgba(255,255,255,0.5) transparent transparent rgba(255,255,255,0.5);
		transform: rotate(45deg);
		animation: up-arrow $transition-time ease-in-out 2s infinite alternate;
		z-index: 2;
	}

	&::after {
		transition: transform $transition-time $transition-bez;
		content: "";
		position: absolute;
		background: rgba(255,255,255,0.5);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		transform: scaleX(0);
	  	transform-origin: left;
	  	z-index: 1;
	}

	&:hover::before {
		border-color: $gray-darker transparent transparent $gray-darker;
	}

	&:hover::after {
		transform: scaleX(1);
		transform-origin: right;
		transition-delay: 0s;
	}
}

@keyframes up-arrow {
    from {
      transform: rotate(45deg) translate(-1px, -1px);
    }
    to {
      transform: rotate(45deg) translate(1px, 1px);
    }
}

.btn-next {
	font-size: 11px;
    position: absolute;
    bottom: 80px;
    padding: 3px 10px;
    border: 1px solid $white;
    color: $white;
    letter-spacing: 2px;
    text-transform: uppercase;

	@include media-breakpoint-up(md) {
		bottom: 60px;
	}


    span {
    	position: relative;
    	z-index: 2;
    }
	strong {
		text-transform: none;
		letter-spacing: 0;
		color: $gray-light;
		font-weight: normal;
	}

	&:hover span {
		color: $gray-darker;
	}
}


.btn-next::after {
	transition: transform $transition-time $transition-bez;
	content: "";
	position: absolute;
	background: $white;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scaleX(0);
  	transform-origin: right;
  	z-index: 1;
}

.btn-next:hover::after {
	transform: scaleX(1);
	transform-origin: left;
	transition-delay: 0s;
}


.btn-info-ext {
	font-size: 9px;
	background: $gray-dark;
	text-transform: uppercase;
	border: 1px solid $gray;
	padding: 0 2px;
    margin: 0 5px;
    color: $white;

    &:hover {
    	background: $white;
    	color: $gray-dark;
    	border-color: transparent;
    }
}


.btn-close-video,
.btn-close-modal,
.btn-open-modal {
	transition: background-color $transition-time ease-in-out;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	position: absolute;
	background-color: $brand-primary;
	top: 30px;
	right: 35px;
	border: 0;
	outline: none;
	color: $white;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	z-index: $zindex-modal;

	&:hover {
		background-color: $gray-dark;
	}
}

.btn-open-modal {
	display: none; // hide on small screens

	@include media-breakpoint-up(md) {
		display: block;
		position: fixed;
		z-index: $zindex-modal - 1;
		background-color: transparent;

		&:hover {
			background-color: $brand-primary;
			animation: shadow-pulse 2s ease-in-out infinite;
			transform-origin: 50% 50%;

			.btn-bar {
				transform: rotate(90deg);	
			}
		}
	}
}

.btn-bar {
	transition: transform $transition-time $transition-bez;
	position: absolute;
	height: 0px;
	border-top: 4px dotted $white;
	width: 20px;
	left: 50%;
	top: 50%;
	margin-top: -2px;
	margin-left: -10px;
}


.btn-slide-bg {
	transition: color $transition-time ease-out;
	position: relative;
	padding: 3px 5px;

	&:hover {
		color: $white;
	}
}

.btn-slide-bg::after {
	transition: transform $transition-time / 2 ease-in-out;
	content: "";
	position: absolute;
	background: rgba(255,255,255,0.05);
	border-radius: 30px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scaleX(0);
	transform-origin: right;
	z-index: -1;
}

.btn-slide-bg:hover::after {
	transform: scaleX(1);
	transform-origin: left;
	transition-delay: 0s;
}