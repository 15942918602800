.video-modal {
	display: none; // Hide by default, use JS to bring it to the DOM
	transition: opacity $transition-time ease-out, transform $transition-time ease-out;
	transform: translate(20px, 0);
	opacity: 0;
	position: fixed;
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	background: rgba(0,0,0,0.95);
	
	z-index: $zindex-tooltip + 1;


	&.open {
		display: flex;
		left: 10px;
		opacity: 1;
		transform: translate(0, 0);
	}
}

.video-modal::before {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 100%;
	position: absolute;
	top: 35px;
	left: 35px;
	background: url(../images/logo-white.svg) no-repeat;

}

.video-modal-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 100px;
	padding-bottom: 100px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}



.characters-modal {
	display: none; // Hide by default, use JS to bring it to the DOM
	transition: opacity $transition-time ease-out, transform $transition-time ease-out;
	transform: translate(20px, 0);
	opacity: 0;
	position: fixed;
	top: $navbar-height;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.95);
	z-index: $zindex-tooltip + 1;

	&.open {
		display: block;
		left: 0;
		opacity: 1;
		transform: translate(0, 0);
	}

	@include media-breakpoint-up(md) {
		top: 0;
		right: 0;
		bottom: 0;
		left: $navbar-height;

		&.open {
			left: $navbar-height;
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}


.nav-overlay {
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: absolute;
	background: $brand-primary;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.nav-overlay-item {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 12.5%;

	position: relative;

	// RESPONSIVE
	@include media-breakpoint-up(sm) {
		width: 50%;
		float: left;
		height: 25%;
	}

	@include media-breakpoint-up(md) {
		width: 50%;
		float: left;
		height: 25%;
	}

	&:hover,
	&:active {
		background: $white;
	}
}

.nav-overlay-item::before {
	transition: opacity $transition-time ease-in-out; 
	opacity: 0;
	content: "";
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.loaded-links & {
		opacity: 1;
	}
}

.nav-overlay-item.nav-bob::before {
	background-image: url(../images/bg-bob.jpg);
}

.nav-overlay-item.nav-mike::before {
	background-image: url(../images/bg-mike.jpg);
}

.nav-overlay-item.nav-man::before {
	background-image: url(../images/bg-the-man.jpg);
}

.nav-overlay-item.nav-giant::before {
	background-image: url(../images/bg-the-giant.jpg);
}

.nav-overlay-item.nav-laura::before {
	background-image: url(../images/bg-laura-palmer.jpg);
}

.nav-overlay-item.nav-chester::before {
	background-image: url(../images/bg-chester-desmond.jpg);
}

.nav-overlay-item.nav-phillip::before {
	background-image: url(../images/bg-phillip-jefferies.jpg);
}

.nav-overlay-item.nav-dale::before {
	background-image: url(../images/bg-dale-cooper.jpg);
}

.nav-overlay-link {
	transition: background ($transition-time / 2) ease-in-out, letter-spacing ($transition-time / 2) ease-in-out;
	position: absolute;
	margin: 0;
	padding: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	letter-spacing: 0;
	color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: $font-family-sans-serif;
	font-size: 10vw;

	// RESPONSIVE
	@include media-breakpoint-up(sm) {
		font-size: 8vw;
	}

		@include media-breakpoint-up(md) {
		font-size: 5vw;
	}

	&:hover {
		color: $white;
		background: rgba(0,0,0,0.5);
		letter-spacing: 3px;

		&::after {
			width: 40px;
			transform: translate(-20px, 0);
		}

		&::before {
			transform: scaleX(1);
			transform-origin: left;
			transition-delay: 0s;
		}
	}
}

.nav-overlay-link span {
	position: relative;
	z-index: 2;
}

.nav-overlay-link::after {
	transition: width $transition-time $transition-bez, transform $transition-time $transition-bez;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	bottom: 20%;
	left: 50%;
	transform: translate(0, 0);
	border-top: 3px solid $brand-primary;
	z-index: 2;
}

.nav-overlay-link::before {
	transition: transform $transition-time $transition-bez;
	content: "";
	position: absolute;
	background: rgba(0,0,0,0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scaleX(0);
	transform-origin: right;
	z-index: 1;
}