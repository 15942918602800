// @include media-breakpoint-up(xs) { ... }
// @include media-breakpoint-up(sm) { ... }
// @include media-breakpoint-up(md) { ... }
// @include media-breakpoint-up(lg) { ... }
// @include media-breakpoint-up(xl) { ... }

.nav-link.navbar-brand {	
    display: block;
    height: $navbar-height;
    line-height: $navbar-height;
    width: 180px !important;
    padding: 2px 10px !important;

    h1 {
    	margin: 0;
    	height: $navbar-height;
    	line-height: $navbar-height;
    	transition: letter-spacing $transition-time ease-in-out, color $transition-time ease-in-out;
		display: block;
		text-align: center;
		font-family: $font-family-sans-serif;
		font-size: 17px;
	    letter-spacing: 2px;
	    text-transform: uppercase;
	    padding: 2px 10px!important;
	    letter-spacing: 2px;
	    color: rgba(255,255,255,0.8);
    }

    &:hover,
    &:active {
    	background-color: transparent;
    }

    @include media-breakpoint-up(md) {
    	position: fixed !important;
    	bottom: 0;
    	left: 0;
    	background: rgba(0,0,0,0.075);
    }
}

.navbar {	
	background: rgba(0,0,0,0.75);

	// RESPONSIVE
	@include media-breakpoint-up(md) {
		top: 0;
		left: 0;
	}

	@include media-breakpoint-up(xl) {
		background: rgba(0,0,0,0.01);
	}
}


.navbar-nav {
	@include media-breakpoint-up(md) {
		display: flex;
		flex: 1;
	}
}


.navbar-toggler {    
    padding: .25rem;
    border: 0;
    border-radius: 0;
    background-color: $brand-primary;
    width: $navbar-height;
    height: $navbar-height;
    outline: none !important;
    cursor: pointer;
}

.navbar-toggler-bar,
.navbar-toggler-bar::before {
	position: absolute;
	top: 50%;
	margin-top: -2px;
	left: 50%;
	margin-left: -10px;
	width: 20px;
	height: 4px;
	background-color: $white;
}

.collapsed .navbar-toggler-bar::before {
	content: "";
	transform: rotate(90deg);
}




.nav-item {
	overflow: hidden;
	background-color: $white;

	// RESPONSIVE
	@include media-breakpoint-up(md) {
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		height: $navbar-height - 10;
		flex: 1;
		border-right: 1px solid rgba(255,255,255,0.1);

		&:last-of-type {
			border-right: 0;
		}


		&:hover {
			&::before {
				transform: rotate(-180deg);
			}
		}
	}
}

// CREATE THE NAV PLANETS
.nav-item::before {
	transition: all ($transition-time / 2) ease-in-out;
	content: "";
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	border-radius: 100%;
	background-color: $gray-darker;
	z-index: -1;
	//opacity: 0.50;
}



.nav-link {
	transition: letter-spacing $transition-time ease-in-out, color $transition-time ease-in-out;
	display: block;
	text-align: center;
	font-family: $font-family-sans-serif-alt;
	font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 10px!important;
    letter-spacing: 2px;
    color: $gray-darker;
    position: relative;
    width: 100%;

    // RESPONSIVE
	@include media-breakpoint-up(md) {
		font-family: $font-family-sans-serif;
		color: rgba(255,255,255,0.8);
		font-size: 17px;
		padding: 2px 10px!important;
	}

    &::before {
    	transition: width $transition-time $transition-bez, border-color $transition-time $transition-bez, transform $transition-time $transition-bez;
		content: "";
		width: 10px;
		bottom: 14px;
		transform: translate(-5px,0);
		position: absolute;
		left: 50%;
		border-top: 1px solid;
		border-color: $brand-primary;
		height: 0;
		z-index: -1;
    }

    &::after {
    	transition: transform $transition-time $transition-bez;
		content: "";
		position: absolute;
		background: rgba(255,255,255,0.05);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: scaleY(0);
		transform-origin: bottom;
    }

    &:hover,
    &:active {
    	color: $brand-primary;
    	letter-spacing: 3px;
    	background-color: darken($white, 5%);

    	// RESPONSIVE
		@include media-breakpoint-up(md) {
			color: rgba(255,255,255,1);
			background-color: transparent;
		}

    	&::before {
    		width: 20px;
    		border-color: $brand-primary;
    		transform: translate(-10px,0);
    	}

    	&::after {
			transform: scaleY(1);
			transform-origin: top;
			transition-delay: 0s;
    	}
    }

    &:focus {
    	color: $white;
    }

	@include media-breakpoint-up(md) {
		//width: $navbar-height;
		text-align: center;
		height: $navbar-height;
		line-height: $navbar-height;
	}
}


// CURRENT PAGE
.curr-nav-sun .nav-item.nav-sun,
.curr-nav-mercury .nav-item.nav-mercury,
.curr-nav-venus .nav-item.nav-venus,
.curr-nav-earth .nav-item.nav-earth,
.curr-nav-mars .nav-item.nav-mars,
.curr-nav-jupiter .nav-item.nav-jupiter,
.curr-nav-saturn .nav-item.nav-saturn,
.curr-nav-uranus .nav-item.nav-uranus,
.curr-nav-neptune .nav-item.nav-neptune {
	background-color: darken($white, 10%);

	@include media-breakpoint-up(md) {
		background-color: rgba(0,0,0,0.05);

		&::before {
			content: "";
			width: 10px;
			bottom: 14px;
			transform: translate(-5px,0);
			position: absolute;
			left: 50%;
			border-top: 1px solid;
			height: 0;
			z-index: -1;
			width: 20px;
			border-color: $brand-primary;
			transform: translate(-10px,0);
		}
	}
}
