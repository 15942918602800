footer .site-by {
	display: none;
	position: fixed;
	bottom: 30px;
	right: 30px;

	// SHOW ONLY ON LARGER SCREENS
	@include media-breakpoint-up(md) {
		display: block;
	}
}

footer #horse {
	fill: rgba(255,255,255,0.5);
	width: 30px; 

	&:hover {
		fill: $brand-primary;
	}
}