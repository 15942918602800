.page {
	height: 100vh;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    // RESPONSIVE
    @include media-breakpoint-up(md) {
		flex-direction: row;
    }


    &::after  {
		content: "";
		transition: transform $transition-time $transition-bez, opacity $transition-time $transition-bez;
		transition-delay: $transition-time;
		opacity: 0;
		transform: translate(30px,0) rotate(0deg);
		border-top: 2px solid rgba(255,255,255,0.065);
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: 5px;
		margin-left: -20%;
		width: 40%;
	    z-index: -1;

        // RESPONSIVE
	    @include media-breakpoint-down(sm) {
			margin-top: -5px;
	    }
	}

	&.sps--abv::after  {
		transform: translate(0,0) rotate(-30deg);
		opacity: 1;
	}
}


.page::before{
	content: "";
	transition: opacity $transition-time ease-in-out, transform ($transition-time * 30) ease-out;
	transition-delay: $transition-time;
	transform: scale(1);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: -1;

	.loaded & {
		opacity: 0.55;
		transform: scale(1.15);
	}
}

.page--home::before {
	background-image: url("../images/bg-solar-system.jpg");
}

.page--sun::before {
	background-image: url("../images/bg-sun.jpg");
}

.page--sun::before {
	background-image: url("../images/bg-sun.jpg");
}

.page--mercury::before {
	background-image: url("../images/bg-mercury.jpg");
}

.page--venus::before {	
	background-image: url("../images/bg-venus.jpg");
}

.page--earth::before {
	background-image: url("../images/bg-earth.jpg");
}

.page--mars::before {
	background-image: url("../images/bg-mars.jpg");
}

.page--jupiter::before {
	background-image: url("../images/bg-jupiter.jpg");
}

.page--saturn::before {
	background-image: url("../images/bg-saturn.jpg");
}

.page--uranus::before {
	background-image: url("../images/bg-uranus.jpg");
}

.page--neptune::before {
	background-image: url("../images/bg-neptune.jpg");
}



.page h1 {
	transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out, letter-spacing $transition-time ease-in-out;
	transform: translate(0, 20px);
	opacity: 0;
	font-size: 15vw;
	letter-spacing: -20px;
	margin: $navbar-height / 2 0 0;

	@include media-breakpoint-down(sm) {
		font-size: 16vw;
		margin-left: 30px;
		margin-right: 30px;
		text-align: center;
	}

	span {
		transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
		transition-delay: $transition-time;
		transform: translate(1vw, 0);
		font-size: 13px;
		position: absolute;
		top: -10px;
		left: 4vw;
		opacity: 0;

		@include media-breakpoint-down(sm) {
			position: absolute;
			left: 50%;
			margin-left: -20px;
			top: -20px;
		}
	}
}




// When scrolled onto
.page.sps--abv h1 {
	transform: translate(0, 0);
	opacity: 1;
	letter-spacing: 0;

	span {
		transform: translate(0, 0);
		opacity: 0.5;
	}
}


// Page layout
.page-col {
	position: relative;
	z-index: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	@include media-breakpoint-up(xl) {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		text-align: left;

		// Vary widths
		&.page--col1 {
			width: 50vw;
		}
		&.page--col2 {
			width: 50vw;
		}
	}
}

// Add borders
.page--col1::after {

	@include media-breakpoint-up(xl) {
		content: "";
		border-right: 1px solid $border-color;
		height: 50vh;
		position: absolute;
		width: 0;
		right: 0;
		top: 50%;
		margin-top: -25vh;
	}
}

.page--col1 {
	padding-bottom: 30px;
	padding-top: 120px;

	@include media-breakpoint-up(xl) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.page--col2 {
	align-items: flex-start;
	border-top: 1px solid $border-color;

	@include media-breakpoint-up(xl) {
		border-top: 0;
	}

}


.page--title,
.page--animation,
.page--snippet  {
	width: 100%;
	padding: 50px 40px 30px;
	font-size: 14px;
}

.page--animation,
.page--snippet {
	@include media-breakpoint-up(xl) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 50vh;
	}
}


.page--snippet {
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 530px;
	padding-left: 15%;
	padding-right: 15%;
	border-top: 1px solid $border-color;
	padding-top: 30px;
	margin-top: 30px;
	margin-left: auto;
    margin-right: auto;
	
	@include media-breakpoint-up(xl) {
		border-top: 0;
		padding-left: 0;
	}
}

.page--title {
	max-width: 600px;
}



.page--animation {
	position: relative;
	display: flex;
	padding-top: 100px;
	justify-content: center;

	@include media-breakpoint-up(xl) {
		&::before {
			margin-top: $navbar-height;
			content: "";
			border-bottom: 1px solid $border-color;
			position: absolute;
			width: 25vw;
			left: 0;
			bottom: 0;
		}
	}
}

.page--animation {
	flex-direction: column;
	align-items: center;
	padding: 50px 40px 10px;

	@include media-breakpoint-up(md) {
		padding: 50px 0 10px;
		flex-direction: row;
	}

	@include media-breakpoint-up(xl) {
		flex-direction: row;
		padding: 140px 40px 0;
	}
}

.page--animation__planet,
.page--animation__satellites {
	@include media-breakpoint-up(sm) {
		flex: 0 0 50%;
	}
}

.page--animation__satellites {
	width: 100%;
	margin-top: 30px;
	@include media-breakpoint-up(md) {
		padding: 0 0 0 5%;
		margin-top: 0;
		text-align: left;
	}
}

.page--animation__planet {
	transition: transform $transition-time $transition-bez, opacity $transition-time ease-out;
	transition-delay: $transition-time;
	max-width: 180px;
	opacity: 0;
	transform: scale(1.2);
	border-radius: 100%;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-up(md) {
		max-width: 200px;
	}

	h4 {
	    position: absolute;
	    color: rgba(255,255,255,0.5)!important;
	    top: 50%;
	    width: 100%;
	    margin: 0 auto;
	    text-align: center;
	    margin-top: -17px;
	    font-size: 40px;
	    z-index: 2;
	}

	// RESPONSIVE HEIGHTS
	@media screen and (max-height: 685px) {
		width: 150px;
	}

	@media screen and (max-height: 500px) {
		width: 100px;
	}

	.sps--blw & {
		opacity: 1;
		transform: scale(1);
	}

	&::before {
		transition: transform $transition-time $transition-bez;
		transition-delay: $transition-time * 2;
		transform-origin: 100% 50%;
		content: "";
		transform: rotate(180deg);
		background: rgba(0,0,0,0.2);
		width: 50%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 1;

		.sps--blw & {
			transform: rotate(0);
		}
	}
}

.img-saturn {
	transform: rotate(-45deg);
}

// Change up for Saturn
.page--animation__planet.saturn-ring {
	overflow: visible;
	
	&::before {
		display: none;
	}
}

// Add a ring for Saturn
.page--animation__planet-saturn-ring {
	position: absolute;
    width: 140%;
    height: 137%;
    left: -24%;
    top: -18%;
    opacity: 0.5;
    background: url(../images/img-saturn-rings.png) no-repeat;
    background-size: contain;

    @include media-breakpoint-up(md) {
    	
    }
	@include media-breakpoint-up(lg) {

	}
	@include media-breakpoint-up(xl) {

	}
}


.page h2 {
	transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
	font-family: $font-family-sans-serif-alt;
	transform: translate(-20px, 0);
	opacity: 0;
	line-height: 1.5;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.2vw;
	margin: $navbar-height / 2 0 0;

	@include media-breakpoint-up(md) {
		&::before,
		&:after {
			transition: width $transition-time * 3 ease-in-out, opacity $transition-time * 3 ease-in-out;
			transition-delay: $transition-time + 2;
			opacity: 0;
			content: "";
			width: 0;
			position: relative;
			top: -3px;
			margin: 0 10px;
			display: inline-block;
			border-top: 1px solid rgba(255,255,255,0.5);
		}
	}
}

// When scrolled onto
.page.sps--abv h2 {
	transition-delay: $transition-time;
	transform: translate(0, 0);
	opacity: 0.4;

	@include media-breakpoint-down(sm) {
		margin-left: 30px;
		margin-right: 30px;
		text-align: center;
	}


	&::before,
	&:after {
		opacity: 1;
		transition-delay: $transition-time;
		width: 10px;
	}
}

.page-info h2,
.page-info h3,
.page-info h4,
.page-info h5 {
	color: $brand-primary;

	span {
		position: relative;
		top: -10px;
	}
}

.page-info-content {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
	top: 150px;

	.text-col {
		margin-bottom: 20px;
	}

	@include media-breakpoint-up(md) {
		top: 30px;
	}

	@include media-breakpoint-up(lg) {
		top: -50px;
	}

	@include media-breakpoint-up(xl) {
		top: -100px;
	}
}

.page-info {
	//height: 100vh;
	min-height: 850px;
	background: $gray-darker;
	display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    flex-direction: column;
    padding-bottom: 150px;

	@include media-breakpoint-up(xl) {
		padding-bottom: 0;
		flex-direction: row;
	}
}


.page-info--home {
	justify-content: flex-start;

	@include media-breakpoint-up(md) {
		justify-content: center;
		background: url(../images/bg-scale-planets.jpg) no-repeat;
		background-size: cover;
		background-position: 50% 25%;
	}
}



.page-info::before {
	transition: opacity $transition-time ease-out, letter-spacing $transition-time $transition-bez;
	transition-delay: $transition-time;
	content: "";
	position: absolute;
	top: $navbar-height;
	left: 0;
	right: 0;
	bottom: 0;
	font-size: 25vw;
	font-family: $font-family-sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $black;
	opacity: 0;
	letter-spacing: -5px;
	z-index: 1;
	text-align: center;
    line-height: 0.95;
}

.page-info.page-info--sun::before {
	content: "Sun";
}

.page-info.page-info--mercury::before {
	content: "Mercury";
}

.page-info.page-info--venus::before {
	content: "Venus";
}

.page-info.page-info--earth::before {
	content: "Earth";
}

.page-info.page-info--mars::before {
	content: "Mars";
}

.page-info.page-info--jupiter::before {
	content: "Jupiter";
}

.page-info.page-info--saturn::before {
	content: "Saturn";
}

.page-info.page-info--uranus::before {
	content: "Uranus";
}

.page-info.page-info--neptune::before {
	content: "Neptune";
}
	

.page-info h1 {
	padding: 3px 5px;	
	display: inline-block;
	margin: 0 0 20px;
	position: relative;
	overflow: hidden;
	line-height: 1;

	&::before {
		content: "";
		transition: transform $transition-time $transition-bez;
		transition-delay: $transition-time;
		background: rgba(255,255,255,0.025);
		transform: translate(-100%, 0);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

// WHEN IN VIEW
.page-info.sps--blw h1::before {
	transform: translate(0, 0);
}



// TRANSITIONS FOR PAGE INFO
.page-info.sps {
	h2, h3 {
		transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
		transition-delay: $transition-time;
		transform: translate(-20px, 0);
		opacity: 0;
		color: $brand-primary;
		font-size: 35px;
		position: relative;
		padding: 10px 0;
	    border-radius: 5px;
	    margin-bottom: 20px;

	    &::before,
	    &::after {
	    	content: "";
	    	border-top: 1px solid $brand-primary;
	    	position: absolute;
	    	width: 10px;
	    	left: 0;
	    	top: 50%;

	    	@include media-breakpoint-up(xl) {
				left: -30px;
	    	}
	    }

	    &:after {
	    	left: inherit;
	    	right: 0;

	    	@include media-breakpoint-up(xl) {
				display: none;
	    	}
	    }

	    // RESPONSIVE
		@include media-breakpoint-up(md) {
			top: 10px;
		}

		span {
			display: block;
			color: $gray;
		}
	}

	h3 {
		font-size: 30px;
	}

	h4 {
		margin-bottom: 15px;
	}



	// WHEN IN VIEW
	&.sps--blw {
		&::before {
			opacity: 0.1;
			letter-spacing: 0;
		}
		h2, h3 {
			transform: translate(0, 0);
			opacity: 1;
		}
	}
}



.profile--dl {
	display: block;
	margin-bottom: 15px;
	font-size: 13px;

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
	}


	&:last-of-type {
		margin-bottom: 30px;
		padding-bottom: 60px;
		border-bottom: 1px solid $border-color;
		
		@include media-breakpoint-up(xl) {
			border-bottom: 0;
			padding-bottom: 0;
		}
	}

	dt {		
		font-family: $font-family-sans-serif-alt;
		font-weight: normal;
		font-size: 12px;
		text-transform: uppercase;
		color: darken($white, 50%);
		position: relative;
    	top: -1px;
    	

		@include media-breakpoint-up(md) {
			margin-right: 10px;
			text-align: right;
		}

    	@include media-breakpoint-up(xl) {
			width: 20%;
			flex: 0 0 20%;
			text-align: left;
			margin-right: 0;
    	}
	}
	dd {
		margin-bottom: 5px;

		@include media-breakpoint-up(xl) {
			width: 80%;
			flex: 0 0 80%;
		}
	}
}

.profile--list,
.profile--satellites {
	padding: 0;
	list-style-type: none;
	margin-bottom: 30px;

	li {
		margin-left: 0;
		position: relative;
		margin-bottom: 5px;

		@include media-breakpoint-up(xl) {
			&::before {
				content: "";
				position: absolute;
				left: -20px;
				top: 12px;
				background: $brand-primary;
				border-radius: 100%;
				width: 5px;
				height: 5px;	
			}
		}
	}
}

.profile--list li {
	margin-bottom: 15px;
	line-height: 1.3;
}


.profile--satellites {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	

	@include media-breakpoint-up(md) {
		text-align: left;
	}

	@include media-breakpoint-up(xl) {
		margin: 0 0 0 20px;
	}
}

.profile--satellites li {
	flex: 0 0 47%;
	font-family: $font-family-sans-serif-alt;
	color: darken($white, 50%);
	text-transform: uppercase;

	@include media-breakpoint-up(sm) {
		margin-left: 0;
		margin-right: 0;
	}
}

.page-info--mercury .profile--satellites li,
.page-info--venus .profile--satellites li,
.page-info--earth .profile--satellites li {
	flex: 0 0 100% !important;

	@include media-breakpoint-up(md) {
		flex: 0 0 47% !important;
	}
}

.sps h2,
.bio-img-col,
.text-col,
.text-center {
	position: relative;
	z-index: 3;
}



.cite-p {
	margin-bottom: 0;
}

cite {
	font-size: 12px;
}